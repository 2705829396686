<template>
  <div>
    <div class="d-flex align-center text-2xl mb-6">
      <span class="headline">Multi Criteria Matching</span>
      <v-spacer />
    </div>
    <div>
      <v-row>
        <v-col sm="6" md="4" lg="4">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">Organisation One</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-select
                  class="mb-3"
                  v-model="leftOrganisationType"
                  label="Organisation Type"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="getTopLevelCompanyTypes"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></v-select>
              </div>
              <div class="d-flex justify-space-between">
                <v-select
                  class="mb-3"
                  v-model="leftOrganisationStage"
                  label="Organisation Stage"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :disabled="!leftCompanyStages.length"
                  :items="leftCompanyStages"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></v-select>
              </div>
              <div class="d-flex justify-space-between">
                <autocomplete
                  class="mb-3"
                  v-model="leftSelectedChallenges"
                  :items="challenges"
                  outlined
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  label="Challenge(s)"
                  multiple
                  hide-details="auto"
                  dense
                ></autocomplete>
              </div>
              <div class="d-flex justify-space-between">
                <autocomplete
                  class="mb-3"
                  v-model="leftSelectedOutcomes"
                  :items="outcomes"
                  outlined
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  label="Outcome(s) of Interest"
                  multiple
                  hide-details="auto"
                  dense
                ></autocomplete>
              </div>
              <div class="red--text">
                <div v-if="errors.atLeastOneSelectionForLeft">At least one field must be selected!</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="4">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">Common Filters</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <autocomplete
                  class="mb-3"
                  v-model="commonSelectedTechAreas"
                  :items="techAreas"
                  outlined
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  label="Innovation Area(s)*"
                  multiple
                  hide-details="auto"
                  dense
                  :error-messages="errors.innovationAreaMissing ? ['This field is required!'] : undefined"
                ></autocomplete>
              </div>
              <div class="d-flex justify-space-between">
                <autocomplete
                  class="mb-3"
                  disabled
                  v-model="commonSelectedTechAreas"
                  :items="techAreas"
                  outlined
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  label="Ticket Size (Soon)"
                  multiple
                  hide-details="auto"
                  dense
                ></autocomplete>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="6" md="4" lg="4">
          <v-card>
            <v-card-title class="justify-center">
              <div class="text-18">Organisation Two</div>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <v-select
                  class="mb-3"
                  v-model="rightOrganisationType"
                  label="Organisation Type"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :items="getTopLevelCompanyTypes"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></v-select>
              </div>
              <div class="d-flex justify-space-between">
                <v-select
                  class="mb-3"
                  v-model="rightOrganisationStage"
                  label="Organisation Stage"
                  outlined
                  dense
                  :menu-props="{ offsetY: true }"
                  :disabled="!rightCompanyStages.length"
                  :items="rightCompanyStages"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></v-select>
              </div>
              <div class="d-flex justify-space-between">
                <autocomplete
                  class="mb-3"
                  v-model="rightSelectedChallenges"
                  :items="challenges"
                  outlined
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  label="Challenge(s)"
                  multiple
                  hide-details="auto"
                  dense
                ></autocomplete>
              </div>
              <div class="d-flex justify-space-between">
                <autocomplete
                  class="mb-3"
                  v-model="rightSelectedOutcomes"
                  :items="outcomes"
                  outlined
                  :menu-props="{ offsetY: true }"
                  item-text="name"
                  item-value="id"
                  label="Outcome(s) of Interest"
                  multiple
                  hide-details="auto"
                  dense
                ></autocomplete>
              </div>
              <div class="red--text">
                <div v-if="errors.atLeastOneSelectionForRight">At least one field must be selected!</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="text-center">
            <v-btn class="my-4" color="primary" outlined min-width="300" style="text-transform: none" @click="generateMatches">
              Show Matches
              <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'MultiMatch',
  components: {},
  data: () => ({
    errors: {
      atLeastOneSelectionForLeft: false,
      atLeastOneSelectionForRight: false,
      innovationAreaMissing: false,
    },
    leftOrganisationType: null,
    leftOrganisationStage: null,
    leftSelectedChallenges: [],
    leftSelectedOutcomes: [],
    commonSelectedTechAreas: [],
    rightOrganisationType: null,
    rightOrganisationStage: null,
    rightSelectedChallenges: [],
    rightSelectedOutcomes: [],
  }),
  computed: {
    ...mapState(['challenges', 'techAreas', 'outcomes', 'companyTypes']),
    ...mapGetters(['getStartupStages', 'getTopLevelCompanyTypes']),
    leftCompanyStages() {
      if (this.leftOrganisationType) {
        return this.companyTypes.filter((c) => c.parent === this.leftOrganisationType);
      }
      return [];
    },
    rightCompanyStages() {
      if (this.rightOrganisationType) {
        return this.companyTypes.filter((c) => c.parent === this.rightOrganisationType);
      }
      return [];
    },
  },
  methods: {
    ...mapActions(['fetchIndividuals', 'deleteIndividual']),
    validate() {
      Object.keys(this.errors).forEach((key) => {
        this.errors[key] = false;
      });

      if (!this.leftOrganisationType && !this.leftOrganisationStage && !this.leftSelectedChallenges.length && !this.leftSelectedOutcomes.length) {
        this.errors.atLeastOneSelectionForLeft = true;
      }
      if (!this.rightOrganisationType && !this.rightOrganisationStage && !this.rightSelectedChallenges.length && !this.rightSelectedOutcomes.length) {
        this.errors.atLeastOneSelectionForRight = true;
      }
      if (!this.commonSelectedTechAreas.length) {
        this.errors.innovationAreaMissing = true;
      }

      return !Object.values(this.errors).some((v) => v === true);
    },
    generateMatches() {
      if (!this.validate()) {
        return;
      }
      this.$router.push({
        path: '/multi-match-result',
        query: {
          leftOrganisationType: this.leftOrganisationType,
          leftOrganisationStage: this.leftOrganisationStage,
          leftSelectedChallenges: this.leftSelectedChallenges.join(','),
          leftSelectedOutcomes: this.leftSelectedOutcomes.join(','),
          commonSelectedTechAreas: this.commonSelectedTechAreas.join(','),
          rightOrganisationType: this.rightOrganisationType,
          rightOrganisationStage: this.rightOrganisationStage,
          rightSelectedChallenges: this.rightSelectedChallenges.join(','),
          rightSelectedOutcomes: this.rightSelectedOutcomes.join(','),
        },
      });
    },
  },
};
</script>
